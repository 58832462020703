import { useEffect } from 'react';
import { Outlet, createFileRoute, redirect, useSearch } from '@tanstack/react-router';

import { datadogRum } from '@datadog/browser-rum';

import UbiquiaAppShell from 'components/UbiquiaAppShell';
import { getUserInfo, isUsingLocalFolders } from 'api/api';
import { useAppStore } from 'stores/appStore';

export const Route = createFileRoute('/_authenticated')({
  beforeLoad: async ({ context }) => {
    const { isLogged } = context.authentication;
    if (!isUsingLocalFolders() && !isLogged()) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw redirect({ to: '/login' });
    }
    if (!useAppStore.getState().userEmail) {
      try {
        const userInfo = await getUserInfo();
        datadogRum.setUser({ email: userInfo.email });
        useAppStore.getState().setUserEmail(userInfo.email);
        localStorage.setItem('nfl-ubiquia-user-id', userInfo.id);
      } catch (error) {
        useAppStore.getState().setUserEmail('');
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw redirect({ to: '/logout' });
      }
    }
  },
  component: () => {
    // @ts-ignore
    const userProvidedScheduleId = useSearch({
      // @ts-ignore
      select: (search) => search.s,
    });
    const [fetchBuildsList, setIsAppLoading] = useAppStore((store) => [
      store.fetchBuildsList,
      store.setIsAppLoading,
    ]);
    useEffect(() => {
      const fetchHelper = async () => {
        // Fetch the builds with a target schedule ID, if provided.
        // TODO(maciek): Now that fetchBuildsList() is done in beforeLoad, we shouldn't have to
        // do a full fetch.
        await fetchBuildsList((userProvidedScheduleId as string) || undefined);
        setIsAppLoading(false);
      };
      fetchHelper();
    }, []);
    return (
      <UbiquiaAppShell>
        <Outlet />
      </UbiquiaAppShell>
    );
  },
});
