import { MantineColorsTuple, createTheme } from '@mantine/core';

const recentiveGreen: MantineColorsTuple = [
  '#f0faf3',
  '#e0f2e6',
  '#bce3c9',
  '#95d5aa',
  '#76c98f',
  '#61c17f',
  '#56be75',
  '#46a763',
  '#3b9457',
  '#2d8149',
];

export const theme = createTheme({
  colors: {
    recentiveGreen,
  },

  fontFamily: 'Helvetica, Verdana, sans-serif',
  fontFamilyMonospace: 'Monaco, Courier, monospace',
  headings: { fontFamily: 'Greycliff CF, sans-serif' },
});
