import {
  MatchupIndex,
  NETWORK_KEYS,
  NETWORK_KEYS_SIMPLE,
  NetworkSummaryIndexSimple,
  RawNetworkSummarySimple,
  RawSchedule,
  SummaryHighlightsInfo,
  TeamType,
  TeamSummaryIndex,
  WEEK_KEYS,
  ViewershipPredictionsDataRaw,
  ViewershipPredictionsRowEntry,
  NetworkType,
  ConstraintEntry,
  WeekType,
  ViewershipPredictionsAverageEntry,
  ViewershipPredictionsIndex,
  WeekSummaryIndex,
  ConstraintType,
  DAYS_IN_ORDER,
  SLOTS_IN_ORDER,
  ConstraintSlotTranslations,
  ConstraintSlot,
  ByeThursIndex,
  RawByeThursSummary,
  TEAM_KEYS,
  ByeThursIndexEntry,
  RestSummaryIndex,
  RestSummaryEntry,
  RawRestSummary,
  HolidaySlotTranslations,
} from './scheduleConsts';

export const getEmptySchedule: () => MatchupIndex[] = () => WEEK_KEYS.map((week) => ({ week }));

export const processRawSchedule = (rawSchedule: RawSchedule): MatchupIndex[] => {
  const retVal = getEmptySchedule();
  Object.entries(rawSchedule).forEach(([networkKey, matchupsArr]) => {
    const [network, day, week, slot] = networkKey.split('.');
    const matchupIndexEntry = retVal.find((entry) => entry.week === Number(week));
    if (!matchupIndexEntry) {
      throw new Error(`No matchupIndexEntry found for week ${week}`);
    }
    matchupsArr.forEach((matchup) => {
      const [team, opponent] = matchup.split('-');
      matchupIndexEntry[team as TeamType] = {
        opponent: opponent as TeamType,
        where: 'home',
        week: Number(week) as WeekType,
        networkKey,
        day,
        network,
        slot,
      };
      matchupIndexEntry[opponent as TeamType] = {
        opponent: team as TeamType,
        where: 'away',
        networkKey,
        week: Number(week) as WeekType,
        day,
        network,
        slot,
      };
    });
  });
  return retVal;
};

export const getSchedule: (id: string) => Promise<MatchupIndex[]> = async (id: string) => {
  const { default: data }: { default: RawSchedule } = await import(
    `../data/schedules/${id}/schedule.json`
  );
  return processRawSchedule(data);
};

export const getEmptyNetworkSummary: () => NetworkSummaryIndexSimple[] = () =>
  Array.from(NETWORK_KEYS_SIMPLE, (key) => ({ network: key }));

export const getEmptyByeThursSummary: () => ByeThursIndex = () => {
  const retVal: ByeThursIndex = [];
  ['bye', 'thurs/fri'].forEach((type) => {
    const temp: ByeThursIndexEntry = { type: type as 'bye' | 'thurs/fri' };
    TEAM_KEYS.forEach((team) => {
      temp[team] = [];
    });
    retVal.push(temp);
  });
  return retVal;
};

export const getEmptyRestSummary: () => RestSummaryIndex = () => {
  const retVal: RestSummaryIndex = [];
  [...WEEK_KEYS, 'total'].forEach((week) => {
    const temp: RestSummaryEntry = { week: week as WeekType | 'total' };
    TEAM_KEYS.forEach((team) => {
      temp[team] = 0;
    });
    retVal.push(temp);
  });
  return retVal;
};

export const processRawNetworkSummarySimple = (
  rawNetworkSummary: RawNetworkSummarySimple
): NetworkSummaryIndexSimple[] => {
  const retVal = getEmptyNetworkSummary();
  Object.entries(rawNetworkSummary).forEach(([teamKey, networkEntries]) => {
    Object.entries(networkEntries).forEach(([networkKey, rating]) => {
      const networkSummaryIndexEntry = retVal.find((entry) => entry.network === networkKey);
      if (!networkSummaryIndexEntry) {
        throw new Error(`No networkSummaryIndexEntrySimple found for network ${networkKey}`);
      }
      networkSummaryIndexEntry[teamKey as TeamType] = rating;
    });
  });
  return retVal;
};

export const getNetworkSummmary: (id: string) => Promise<NetworkSummaryIndexSimple[]> = async (
  id: string
) => {
  const { default: data }: { default: RawNetworkSummarySimple } = await import(
    `../data/schedules/${id}/summary_network_teams.json`
  );
  return processRawNetworkSummarySimple(data);
};

export const processRawByeThursSummary = (
  rawByeThursSummary: RawByeThursSummary
): ByeThursIndex => {
  const retVal = getEmptyByeThursSummary();
  Object.entries(rawByeThursSummary).forEach(([teamKey, entries]) => {
    const byeThursEntry = retVal.find((entry) => entry.type === 'bye');
    if (!byeThursEntry) {
      throw new Error('No byeThursEntry found for type bye');
    }
    byeThursEntry[teamKey as TeamType] = entries.bye;
    const thursFriEntry = retVal.find((entry) => entry.type === 'thurs/fri');
    if (!thursFriEntry) {
      throw new Error('No thursFriEntry found for type thurs/fri');
    }
    thursFriEntry[teamKey as TeamType] = entries['thurs/fri'];
  });
  return retVal;
};

export const getByeThursSummary: (id: string) => Promise<ByeThursIndex> = async (id: string) => {
  try {
    const { default: data }: { default: RawByeThursSummary } = await import(
      `../data/schedules/${id}/summary_bye_thurs.json`
    );
    return processRawByeThursSummary(data);
  } catch (e) {
    return getEmptyByeThursSummary();
  }
};

export const processRawRestSummary = (rawRestSummary: RawRestSummary): RestSummaryIndex => {
  const retVal = getEmptyRestSummary();
  Object.entries(rawRestSummary).forEach(([teamKey, restValues]) => {
    Object.entries(restValues).forEach(([week, value]) => {
      const restSummaryEntry = retVal.find((entry) => String(entry.week) === week);
      if (!restSummaryEntry) {
        throw new Error(`No restSummaryEntry found for week ${week}`);
      }
      restSummaryEntry[teamKey as TeamType] = value;
    });
  });

  return retVal;
};

export const getRestSummary: (id: string) => Promise<RestSummaryIndex> = async (id: string) => {
  try {
    const { default: data }: { default: RawRestSummary } = await import(
      `../data/schedules/${id}/summary_rest.json`
    );
    return processRawRestSummary(data);
  } catch (e) {
    return getEmptyRestSummary();
  }
};

export const getSummaryHighlights: (id: string) => Promise<SummaryHighlightsInfo> = async (
  id: string
) => {
  const { default: data }: { default: SummaryHighlightsInfo } = await import(
    `../data/schedules/${id}/summary_highlights.json`
  );
  return data;
};

export const getTeamSummary: (id: string) => Promise<TeamSummaryIndex> = async (id: string) => {
  const { default: data }: { default: TeamSummaryIndex } = await import(
    `../data/schedules/${id}/summary_teams.json`
  );
  return data;
};

export const processViewershipPredictionsDataRaw = (
  viewershipPredictionsRaw: ViewershipPredictionsDataRaw
) => {
  const viewershipIndex: ViewershipPredictionsRowEntry[] = WEEK_KEYS.filter(
    (week) => week !== 18
  ).map(
    (week) =>
      ({
        week,
        ...NETWORK_KEYS.reduce((acc, network) => ({ ...acc, [network]: [] }), {}),
        // Casting here to avoid an error.
      }) as ViewershipPredictionsRowEntry
  );
  const averageEntries: ViewershipPredictionsAverageEntry[] = [];
  Object.entries(viewershipPredictionsRaw).forEach(([networkKey, predictionEntries]) => {
    Object.entries(predictionEntries.weeks).forEach(([week, gameEntries]) => {
      const viewershipEntry = viewershipIndex.find((entry) => entry.week === Number(week));
      if (!viewershipEntry) {
        throw new Error(`No viewershipEntry found for week ${week}`);
      }
      Object.entries(gameEntries).forEach(([matchup, { viewers, highlight }]) => {
        viewershipEntry[networkKey as NetworkType].push({ matchup, viewers, highlight });
      });
    });

    averageEntries.push({ network: networkKey as NetworkType, ...predictionEntries.avg });
  });

  return { viewership: viewershipIndex, averages: averageEntries };
};

export const getViewershipPredictions: (id: string) => Promise<ViewershipPredictionsIndex> = async (
  id: string
) => {
  const { default: data }: { default: ViewershipPredictionsDataRaw } = await import(
    `../data/schedules/${id}/summary_network_games.json`
  );

  return processViewershipPredictionsDataRaw(data);
};

export const getConstraints: (id: string) => Promise<ConstraintEntry[]> = async (id: string) => {
  const { default: data }: { default: any } = await import(
    `../data/schedules/${id}/constraints.json`
  );
  return Object.values(data) as ConstraintEntry[];
};

export const getViewershipDisplayValue: (value: unknown | number | string) => string = (value) =>
  // Nine Zeroes for Billions
  Math.abs(Number(value)) >= 1.0e9
    ? `${(Math.abs(Number(value)) / 1.0e9).toFixed(2)}B`
    : // Six Zeroes for Millions
      Math.abs(Number(value)) >= 1.0e6
      ? `${(Math.abs(Number(value)) / 1.0e6).toFixed(2)}M`
      : // Three Zeroes for Thousands
        Math.abs(Number(value)) >= 1.0e3
        ? `${(Math.abs(Number(value)) / 1.0e3).toFixed(2)}K`
        : `${Math.abs(Number(value))}`;

export const getWeekSummary: (id: string) => Promise<WeekSummaryIndex> = async (id: string) => {
  const { default: data }: { default: WeekSummaryIndex } = await import(
    `../data/schedules/${id}/summary_weeks.json`
  );
  return data;
};

export const weekdaySorter = (a: string, b: string) =>
  DAYS_IN_ORDER.indexOf(a) - DAYS_IN_ORDER.indexOf(b);

export const slotSorter = (a: string, b: string) =>
  SLOTS_IN_ORDER.indexOf(a) - SLOTS_IN_ORDER.indexOf(b);

export const getStringifiedConstraint = (entry: ConstraintEntry) => {
  switch (entry.type) {
    case ConstraintType.MATCHUP_GUARANTEE:
      return `${entry.params.matchup} ${entry.params.inclusive ? 'plays' : 'does not play'} on ${entry.params.network.sort().join(', ')}${entry.params.holiday_slot ? ` during ${HolidaySlotTranslations[entry.params.holiday_slot]}` : ''}${entry.params.week.includes('All') ? '' : ` on week ${entry.params.week.sort((a, b) => Number(a) - Number(b)).join(', ')}`}`;
    case ConstraintType.STADIUM_BLOCK:
      // eslint-disable-next-line no-case-declarations
      const allSelected = entry.params.slot.includes(ConstraintSlot.ALL);
      // eslint-disable-next-line no-case-declarations
      const slotText = `${
        allSelected
          ? ''
          : ` during ${entry.params.slot
              .sort(slotSorter)
              .map((slot) => ConstraintSlotTranslations[slot])
              .join(', ')}`
      }`;
      return `${entry.params.team} stadium is not available${slotText} on week ${entry.params.week}`;

    case ConstraintType.TEAM_GUARANTEE:
      return `${entry.params.team} plays ${entry.params.min_appearances}${entry.params.min_appearances === entry.params.max_appearances ? '' : `-${entry.params.max_appearances}`} time(s) on ${entry.params.network.join(', ')}${entry.params.holiday_slot ? ` during ${HolidaySlotTranslations[entry.params.holiday_slot]}` : ''}${entry.params.week.includes('All') ? '' : ` on week ${entry.params.week.sort((a, b) => Number(a) - Number(b)).join(', ')}`}`;
    case ConstraintType.BYE_GUARANTEE:
      return `${entry.params.team} ${entry.params.inclusive ? 'has' : 'does not have'} a bye week on week(s) ${entry.params.week.sort((a, b) => Number(a) - Number(b)).join(', ')}`;
    case ConstraintType.HOME_AWAY_GUARANTEE:
      return `${entry.params.team.join(' or ')} plays ${entry.params.home_away === 'home' ? 'at Home' : 'Away'} ${entry.params.min_appearances}${entry.params.min_appearances === entry.params.max_appearances ? '' : `-${entry.params.max_appearances}`} time(s)${entry.params.holiday_slot ? ` during ${HolidaySlotTranslations[entry.params.holiday_slot]}` : ''}${entry.params.network.includes('All') ? '' : ` on ${entry.params.network.join(', ')}`}${entry.params.week.includes('All') ? '' : ` in week(s) ${entry.params.week.sort((a, b) => Number(a) - Number(b)).join(', ')}`}`;
    case ConstraintType.PRIMETIME_MIN_MAX:
      return `${entry.params.team.join(', ')} plays ${entry.params.min_appearances}${entry.params.min_appearances === entry.params.max_appearances ? '' : `-${entry.params.max_appearances}`} time(s)${entry.params.network.includes('All') ? '' : ` on ${entry.params.network.join(', ')}`}`;
    default:
      return 'Unknown Constraint';
  }
};

export const removeDuplicateConstraints = (constraints: ConstraintEntry[]) => {
  const constraintMap = new Map<string, ConstraintEntry>();
  constraints.forEach((constraint) => {
    const key = getStringifiedConstraint(constraint);
    if (!constraintMap.has(key)) {
      constraintMap.set(key, constraint);
    }
  });
  return Array.from(constraintMap.values());
};
